<template>
  <div v-if="detail.workTaskUser">
    <div style="background: #fff;">
      <div class="taskItem flex flex-center">
        <div>任务名称</div>
        <div>{{ detail.workTask.name }}</div>
      </div>

      <div class="taskItem flex flex-center">
        <div>任务状态</div>
        <div class="flex flex-center">
          <div class="taskItem-text">
            {{ detail.workTaskUser.status == 0 ? "未完成" : "已完成" }}
          </div>
        </div>
      </div>

      <div class="taskItem flex flex-center">
        <div>开始时间</div>
        <div class="flex flex-center">
          <div class="taskItem-text">{{ detail.workTask.start_date }}</div>
        </div>
      </div>

      <div class="taskItem flex flex-center">
        <div>结束时间</div>
        <div class="flex flex-center">
          <div class="taskItem-text">{{ detail.workTask.end_date }}</div>
        </div>
      </div>
      <!-- <div class="taskItem flex flex-center">
        <div>任务类型</div>
        <div class="flex flex-center">
          <div class="taskItem-text">
            {{ detail.workTask.type == 1 ? "全员" : "指定人员" }}
          </div>
        </div>
      </div> -->

      <div class="remind-textarea">
        <div class="remindText-title">任务说明</div>
        <div class="textarea" v-if="detail.workTaskUser.content">
          {{ detail.workTaskUser.content }}
        </div>
        <textarea
          placeholder="请填写任务说明"
          v-model="remark"
          v-else
        ></textarea>
      </div>

      <div class="rulesView">
        <div class="rulesView-title">上传附件</div>
        <img
          class="rulesView-add"
          src="../assets/imgs/add.png"
          alt=""
          @click="triggerUpload"
          v-if="!img_url"
        />
        <input
          v-if="!img_url"
          id="filePhoto"
          type="file"
          ref="file"
          accept="image/*"
          hidden
          @change="onInputChange($event)"
        />

        <div class="rightullidiv" v-if="detail.workTaskUser.img_url">
          <img :src="detail.workTaskUser.img_url" alt="" class="rightulliimg" />
        </div>
        <div class="rightullidiv" v-else>
          <img :src="img_url" alt="" class="rightulliimg" />
        </div>
      </div>

      <!-- <div class="taskItem flex flex-center">
        <div>选择群聊</div>
        <div class="flex flex-center">
          <div class="taskItem-text">北京群、广州群</div>
          <img class="taskItem-icon" src="../assets/imgs/arrow.png" alt="" />
        </div>
      </div> -->
    </div>

    <!-- <div class="taskCAll">
      <div class="taskC">
        <div class="flex flex-center">
          <div class="taskC-cirle flex-item_center">
            1
          </div>
          <div>
            <div class="taskC-top flex flex-center">
              <div>双11大促活动任务</div>
              <img class="taskC-topImg" src="../assets/imgs/edit.png" alt="" />
            </div>
            <div class="taskC-bottom flex flex-center">
              8月20日 22:00
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <!-- <div
      class="taskAll-btn flex-item_center"
      @click="router('/CreateTaskRules')"
    >
      添加任务规则
    </div> -->

    <!-- 底部固定 -->
    <div class="bottom fixed flex">
      <div
        class="bottom-add flex-item_center"
        @click="complete()"
        v-if="!detail.workTaskUser.img_url && !detail.workTaskUser.content"
      >
        完成任务
      </div>
      <div class="bottom-add flex-item_center" @click="goBack()" v-else>
        返回
      </div>
    </div>
  </div>
</template>

<script>
import VuePicker from "vue-pickers";
import * as request from "../api/data";
import _ from "lodash";
import { Toast } from "vant";
import axios from "axios";
import config from "@/config";

export default {
  components: { VuePicker },
  data() {
    return {
      remark: "",
      detail: {
        workTask: {
          name: "",
          status: "",
        },
      },
      img_url: "",
    };
  },
  async mounted() {
    let that = this;
    let res = await request._taskDetail({
      userid: localStorage.getItem("userid"),
      work_task_id: this.$route.query.work_task_id,
    });
    if (_.toInteger(res.code) === 1) {
      this.detail = res.data;
      if (res.data.workTaskUser.img_url) {
        this.img_url = true;
      }
    } else {
      Toast(res.msg);
      setTimeout(() => {
        that.$router.back();
      }, 1500);
    }
    console.log("任务详情", res);
  },
  methods: {
    async complete() {
      let that = this;
      if (this.remark == "") {
        Toast("请输入内容");
        return false;
      }
      if (this.img_url == "") {
        Toast("请上传图片");
        return false;
      }

      let res = await request._overtask({
        content: this.remark,
        img_url: this.img_url,
        userid: localStorage.getItem("userid"),
        work_task_id: this.detail.workTaskUser.work_task_id,
      });

      if (_.toInteger(res.code) === 1) {
        Toast.success("提交成功");
        setTimeout(() => {
          that.$router.back();
        }, 1200);
      } else {
        Toast(res.msg);
      }
    },
    goBack() {
      this.$router.back();
    },

    router(path) {
      this.$router.push(path);
    },

    triggerUpload() {
      $("#filePhoto").click();
    },
    //文件上传到浏览器触发事件
    async onInputChange(el) {
      var that = this;
      var file = el.target.files[0];
      console.log(file);
      var type = file.type.split("/")[0];

      let formData = new FormData();
      formData.append("file", file);
      console.log(formData);
      // let res = await request._uploadImg(formData);
      axios({
        method: "post",
        url: config.baseUrl.dev + "workapi/mobile.User/upload",
        data: formData,
      }).then(
        (res) => {
          console.log("Res", res);
          if (res.data.code == 1) {
            that.img_url = res.data.data.img_url;
          }
        },

        (err) => {}
      );
    },
  },
};
</script>

<style scoped>
.remind-textarea {
  margin: 0 auto;
  width: 670px;
  background: #fff;
  border-bottom: 1px solid #ededf7;
  padding: 64px 0 26px 0;
}

.remindText-title {
  font-size: 30px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #66728f;
}

.textarea {
  outline: none;
  border: 0;
  width: 670px;
  margin-top: 38px;
  height: 110px;
  font-size: 30px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #101f48;
}
textarea {
  outline: none;
  border: 0;
  width: 670px;
  margin-top: 38px;
  height: 110px;
  font-size: 30px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #101f48;
}

.rulesView {
  width: 670px;
  padding: 52px 0 88px 0;
  margin: 0 auto;
}

.rulesView-title {
  font-size: 30px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #66728f;
  margin: 0 0 37px 0;
}

.rulesView-add {
  width: 180px;
  height: 180px;
  background: #ededf7;
  border-radius: 16px 16px 16px 16px;
}

.taskItem {
  width: 670px;
  padding: 64px 0 26px 0;
  border-bottom: 1px solid #ededf7;
  font-size: 30px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #66728f;
  margin: 0 auto;
  background: #fff;
  justify-content: space-between;
}

.taskItem input {
  text-align: right;
  outline: none;
  border: 0;
  font-size: 30px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
}

.taskItem-icon {
  width: 12px;
  height: 20px;
  margin-left: 8px;
}

.taskItem-text {
  font-size: 30px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #101f48;
}

.taskC {
  width: 670px;
  margin: 0 auto;
}

.taskC-cirle {
  font-size: 32px;
  font-family: Akrobat-Bold, Akrobat;
  font-weight: bold;
  color: #ffffff;
  width: 54px;
  height: 54px;
  background: #0558f4;
  border-radius: 50%;
  margin-right: 46px;
}

.taskCAll {
  padding: 83px 0 48px 0;
  background: #fff;
}

.taskC-top {
  width: 568px;
  height: 76px;
  padding: 0 0 0 16px;

  background: #f4f5f7;
  justify-content: space-between;
  border-radius: 16px 16px 0px 0px;
}

.taskC-bottom {
  width: 568px;
  height: 96px;
  padding: 0 0 0 16px;
  border-radius: 0 0 16px 16px;
  border: 1px solid #e0e4eb;
}

.taskAll-btn {
  width: 670px;
  height: 80px;
  background: #ffffff;
  box-shadow: 0px 10px 50px 1px rgba(5, 88, 244, 0.1);
  border-radius: 16px 16px 16px 16px;
  margin: 48px auto 0;
  font-size: 30px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #0558f4;
}

.bottom {
  width: 100%;
  bottom: 0;
  left: 0;
  height: 151px;
  background: #ffffff;
  justify-content: center;
  border-top: 1px solid #c3c3c3;
}

.bottom-btn {
  width: 325px;
  height: 80px;
  border-radius: 16px 16px 16px 16px;
  opacity: 1;
  border: 2px solid #0558f4;
  font-size: 30px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #0558f4;
  margin: 15px 0 0 0;
}

.bottom-btn_save {
  width: 325px;
  margin: 15px 0 0 20px;
  height: 80px;
  background: #0558f4;
  border-radius: 16px 16px 16px 16px;
  font-size: 30px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #ffffff;
}

.taskC-topImg {
  width: 27px;
  height: 28px;
  margin-right: 30px;
}

.remind-check {
  width: 670px;
  padding: 52px 0 88px 0;
  margin: 0 auto;
  border-bottom: 1px solid #ededf7;
}

.remind-check_item {
  font-size: 24px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #101f48;
  background: #f1f3ff;
  border-radius: 4px 4px 4px 4px;
  padding: 7px 39px;
  margin: 0 17px 17px 0;
}

.remind-check_selectItem {
  color: #0558f4;
  border: 1px solid #0558f4;
  background: #e8f0fe;
}

.rightullidiv {
  width: 100%;
  display: flex;
  justify-content: center;
  background: #fff;
  align-items: center;
  padding-bottom: 260px;
}

.rightulliimg {
  max-width: 100%;
  max-height: 700px;
}

.bottom-add {
  width: 670px;
  height: 80px;
  background: #0558f4;
  border-radius: 16px 16px 16px 16px;
  font-size: 30px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #ffffff;
  margin-top: 15px;
}
</style>
